var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "py-8 mx-16 main" },
    [
      _c(
        "v-row",
        { staticClass: "px-2" },
        [
          _c("v-col", { staticClass: "pa-0 grey lighten-4" }, [
            _c("div", { staticClass: "text-h5 mb-4" }, [_vm._v("TRAVERSA")]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mb-8 px-2" },
        [
          _c(
            "v-col",
            { staticClass: "pa-0 grey lighten-4", attrs: { cols: "12" } },
            [
              _c(
                "label",
                {
                  staticClass: "select-label",
                  attrs: { for: "data-type-select" },
                },
                [_vm._v("Choose data type you want to import")]
              ),
              _c("v-select", {
                staticClass: "select mb-8",
                attrs: {
                  "menu-props": { bottom: true, offsetY: true },
                  items: _vm.options,
                  outlined: "",
                  id: "data-type-select",
                },
                model: {
                  value: _vm.selectedOption,
                  callback: function ($$v) {
                    _vm.selectedOption = $$v
                  },
                  expression: "selectedOption",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "px-2" },
        [
          _c(
            "v-col",
            { staticClass: "pa-0" },
            [
              _c(
                "v-card",
                { staticClass: "grey lighten-4" },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("div", [
                        _c("label", [
                          _vm._v(
                            "Upload " + _vm._s(_vm.selectedOption) + " csv file"
                          ),
                        ]),
                      ]),
                      !_vm.importing
                        ? [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c("v-file-input", {
                                      attrs: {
                                        value: _vm.fileValue,
                                        placeholder: "Upload File",
                                      },
                                      on: { change: _vm.upload },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mx-2",
                                        attrs: { color: "primary" },
                                        on: { click: _vm.convertData },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.selectedOption == "Trips"
                                                ? "Validate"
                                                : "Import"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.selectedOption == "Trips",
                                            expression:
                                              "selectedOption == 'Trips'",
                                          },
                                        ],
                                        staticClass: "mx-2",
                                        attrs: {
                                          color: "primary",
                                          disabled:
                                            _vm.selectedOption == "Trips" &&
                                            !_vm.validated,
                                        },
                                        on: { click: _vm.importTrips },
                                      },
                                      [_vm._v(" Import ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _c("v-progress-circular", {
                            staticClass: "progress",
                            attrs: {
                              size: 50,
                              color: "primary",
                              indeterminate: "",
                            },
                          }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.selectedOption == "Trips" && _vm.validated,
              expression: "selectedOption == 'Trips' && validated",
            },
          ],
        },
        [
          _c(
            "v-col",
            [
              _c("v-simple-table", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _c("thead", [
                          _c("tr", [
                            _c("th", { staticClass: "text-left" }, [
                              _vm._v("Trip ID"),
                            ]),
                            _c("th", { staticClass: "text-left" }, [
                              _vm._v("Missing Location/Destination"),
                            ]),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          _vm._l(_vm.missing, function (item, i) {
                            return _c("tr", { key: i }, [
                              _c("td", [_vm._v(_vm._s(item.id))]),
                              _c("td", [_vm._v(_vm._s(item.value))]),
                            ])
                          }),
                          0
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }